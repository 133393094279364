.link-back{
    // background: black;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 5px;
    height: 66.8px;
    color: #F1582E;
    font-weight: 500;
    font-size: 25.2px;
    line-height: 37.8px;
}
.link-back:hover{
    color: #F1582E;
}

.detail-komoditas{
    color: black;
    .item-komoditas{
        padding-top: 88.2px;
        display: flex;
        justify-content: space-between;
        align-items: start;
        gap: 54px;
        flex-wrap: wrap;
        img{
            width: 579px;
            height: 404px;
            border-radius: 0px 350px 350px 350px;
        }
        .text-komoditas{
            width: 466.3px;
            h1{
                font-size: 66px;
                line-height: 100.3px;
                text-transform: capitalize;
                font-weight: 600;
                margin-bottom: 0;
            }
            p{
                font-weight: 500;
                font-size: 25.2px;
                line-height: 37.8px;
            }
        }
    }
    h3{
        font-weight: 600;
        font-size: 33.6px;
        line-height: 50.4px;
    }
}

@media (max-width:1200px){
    .detail-komoditas{
        img{
            width: 80% !important;
            margin: auto;
        }
        .text-komoditas{
            width: 100% !important;
        }
        h3{
            margin-bottom: 70px;
        }
    }
}