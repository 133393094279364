#aparatur-desa {
    padding: 74.2px 0 102px 0;
    position: relative;
    width: 100%;

    h3 {
        margin-bottom: 0;
        color: $black;
        font-weight: 600;
        font-size: 33.6px;
        line-height: 50.4px;
        text-align: center;
    }

    h4 {
        font-weight: 700;
        font-size: 22.4px;
        line-height: 33.6px;
        color: white;
        margin: 0;
    }

    p {
        font-weight: 500;
        font-size: 16px;
        line-height: 25px;
        color: white;
        margin: 7px 0 0 0;
    }

    .slick-arrow {
        display: none !important;
    }

    .top-dekor {
        position: absolute;
        width: 46px;
        height: 27px;
        top: 0;
        left: 48.4%;
        right: 48.4%;
    }

    .slick-slide {
        display: flex;
        justify-content: center;
        align-items: center;
        div {
            margin: 0;
        }
    }

    :focus {
        outline: none !important;
    }

    .person {
        margin-top: 119.7px !important;
        position: relative;
        overflow: hidden;
        border-radius: 87.5px 0px 129.5px;
        width: 318.5px !important;
        height: 440.8px;
        border: 14px solid #F1582E;
        background-color: #F1582E;
        img {
            width: 100%;
            height: 100%;
        }
        .person-text {
            width: 100%;
            position: absolute;
            padding: 20px 10.5px 10.5px;
            height: 50%;
            background-image: linear-gradient(to bottom, transparent, #F1582E);
            bottom: 0;
        }
    }

    // Media Queries for Responsiveness
    @media screen and (max-width: 1200px) {
        h3 {
            font-size: 28px;
            line-height: 42px;
        }

        .person {
            width: 280px !important;
            height: 390px;
        }

        .top-dekor {
            width: 40px;
            height: 22px;
        }
    }

    @media screen and (max-width: 992px) {
        h3 {
            font-size: 26px;
            line-height: 39px;
        }

        .person {
            width: 260px !important;
            height: 370px;
        }

        .top-dekor {
            width: 38px;
            height: 20px;
        }
    }

    @media screen and (max-width: 768px) {
        h3 {
            font-size: 24px;
            line-height: 36px;
        }

        .person {
            width: 220px !important;
            height: 320px;
        }

        .top-dekor {
            width: 35px;
            height: 18px;
        }
    }

    @media screen and (max-width: 576px) {
        h3 {
            font-size: 20px;
            line-height: 30px;
        }

        .person {
            width: 180px !important;
            height: 260px;
        }

        .top-dekor {
            width: 30px;
            height: 15px;
        }

        .person-text {
            padding: 15px 8px 8px;
        }
    }
}
