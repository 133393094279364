#DetailArtikel{

    .container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .news-content {
        flex-basis: 65%;
        
        .news-date {
            font-size: 12px;
            color: #808080;
        }
        
        img {
            max-width: 100%;
            padding-bottom: 15px;
        }
    }

    .news-list {
        flex-basis: 30%;
        
        ul {
            list-style: none;
            padding: 0;
        }

        ul li {
            margin-bottom: 10px;
        }

        ul li a {
            text-decoration: none;
            color: #000;
        }
        
    }


    .news-card {
        display: flex;
        align-items: center;
        padding: 5px;
        border-radius: 5px;

        .news-image {
            // background-image: url('https://images.app.goo.gl/Kjvct2whRHBgMmuA6');
            // background-position: center;
            // background-size: cover;
            display: flex;
            justify-content: center;
            flex-basis: 50%;
            margin-right: 10px;
            overflow: hidden;
            border-radius: 5px;
            img {
                width: 70px;
                height: 70px;
                object-fit: cover;
                border-radius: 5px;
            }
        }
        
            
            h3 {
                font-size: 18px;
                margin: 0;
            }
            
            p {
                margin: 10px 0 0;
                font-size: 10px;
                line-height: 1.5;
                text-align: justify;
                color: #808080;
            }
            
        }
        
        
        .orange-elipse {
            font-size: 25px;
            font-weight: 500;
            padding: 5px;
            background-color: orange;
            border-radius: 10px;
        color: white;
        text-align: center;
    }
}

@media (max-width: 768px) {

    #DetailArtikel{

        .container {
            padding: 15px;
        }
        
        .news-card {
            flex-wrap: wrap;
            justify-content: center;
            .news-image {
                flex-basis: 100%;
                justify-content: center;
                img {
                    width: 70px;
                    height: 50px;
                    object-fit: cover;
                    border-radius: 5px;
                }
            }
            h6 {
                margin-top: 5px;
                font-size: 10px;
            }
            p {
                font-size: 6px;
            }
        }
        
        .orange-elipse {
            font-size: smaller;
        }
        
        .news-content {
            h2 {
                font-size: 20px;
            }
            .news-date {
                font-size: 7px;
            }
            p {
                color: #000;
                font-size: 12px;
            }
        }
    }
}