

.footer {    
    color: $black !important;
    background:  #E3E3E3;
    width: 100%;

    .container{   
        .wrapper-content-footer {
           padding-top: 20px;
           .logo-wrapper {
            display: flex;
            align-items: center;
            .logo{
                width: 100px;
                margin: auto;
                padding-bottom: 10px;
            }
           }
          
            .social-media{
                margin-top: 10px;
                display: flex;
                gap: 65px;
                justify-content: center;
                margin-bottom: 30px;   
                img{
                    width: 40px !important;
                }
            
            }
            .language {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $basic;
                border-radius: 30px;  
                padding: 10px;
                color: $white;
            
            }
            h5{
                font-size: 20px;
                font-weight: 600;
                line-height: 34px;
            }
            p{
                font-size: 14px;
                line-height: 17px;
                font-weight: 500;
            }
        }
       
    }
   

}


  @media (max-width: 576px) {
    .footer {    
  .language{
  margin-bottom: 20px;
  }
    }
}


