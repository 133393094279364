#sejarah {
    overflow: hidden!important;
    .image-gallery-thumbnail:hover, 
     .image-gallery-thumbnail.active, 
     .image-gallery-thumbnail:focus {
        border: 4px solid $basic!important;
        
    }
    .content-wrapper {
        position: relative;
        height: 400px;
    }

    .image-gallery-content.bottom {
        position: absolute;
    }

    .image-gallery-thumbnails-wrapper.bottom.thumbnails-swipe-horizontal {
        position: absolute;
        left: calc(100% + 25px) ;
        bottom: -6px;
        z-index: 1;
        
    }
    .image-gallery-thumbnails-container {
        overflow-x: hidden!important;
        max-width: 100%;
        padding-right: 120px;
        // height: 150px;
        // border: 1px solid black;
        display: flex;
        justify-content: center;
        flex-wrap: nowrap;
      

    }
    .image-gallery-thumbnail {
        margin: 0 auto;
        margin-right: 25px;
        // width: 120px;
            // height: 100px;
        
        .image-gallery-thumbnail-image {
            // width: 120px;
            height: 92px;
            // margin-right: 10px;
            // padding-right: 20px;
            
        }
    }
   
    
  margin-top: 100px;
    .container {
        .top-dekor{
            width: 46px;
            height: 27px;
            margin-bottom: 50px;
        }

       .content-wrapper {
        h3.title {
            color: $black;
            font-weight: 600;
            font-size: 33.6px;
        }
            .left-side {
                h3.title{
                    display: none;
                }
            //     .image-gallery-slide-wrapper.bottom,
            //     .image-gallery-using-mouse,
            //   .image-gallery-swipe,
            //    .image-gallery-slide-wrapper, 
            //    .image-gallery-slides > div,
            //    .image-gallery-slide ,
               img.image-gallery-image {
                    // border-radius: 100px 20px 20px 20px;
                    // border-radius: 50px!important;
                }

                .image-gallery-slide {

                    // transform: translate3d(0px, 0px, 0px)!important
                }
            }
        

            .right-side {
                // h3{
                //     color: $black;
                //     font-weight: 600;
                //     font-size: 33.6px;
                //     // line-height: 50.4px;
                // }
                p {
                    font-weight: 500;
                    font-size: 20px;
                    span {
                        color: $basic;
                        cursor: pointer;
                    }
                }

            }      
        
       }

    }
}

@media (max-width: 1200px )  {
    #sejarah {
    .content-wrapper {
        position: static;
        height: inherit;
        .left-side {
            h3.title {
                display: block!important;
            }
        }
        .right-side {
            h3.title{display: none;}
        }
    }

    .image-gallery-content.bottom {
        position: static;
    }

    .image-gallery-thumbnails-wrapper.bottom.thumbnails-swipe-horizontal {
        position: static;
        left: inherit;
        bottom: inherit; 
    }
    .image-gallery-thumbnails-container {
        max-width: inherit;
        padding-right: 0px;
        display: block;
    }
    .image-gallery-thumbnail {
        margin: 0 auto;
        margin-right: 0px;
        
        .image-gallery-thumbnail-image {
            height: inherit;
        }
    }

    }
}

@media (max-width: 992px )  {
    #sejarah {
        margin-top: 50px;
        .right-side {
            p {
                margin-top: 10px;
            }
        }
//     .container {
//     .left-side {
//     display: flex!important;
//     justify-content: center;
//     .show-image {

//         margin: 0 auto;
//         width: 300px;
//         height: 500px;
//     }
//  }
//  .right-side {
//     .list-image-wrapper {
//         justify-content: center!important;
//     }
//     }
//  }
  
}
}





    