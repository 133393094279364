//
// menu.scss
//

.nav-item {
    .nav-link {
        display: inline-block;
        color: $dark;
        text-decoration: none;
    }
}
.navbar {
    .menu-social-icon {
        li {
            margin: 0 8px;
            .menu-social-link {
                color: $muted;
                transition: all 0.5s;
                &:hover {
                    color: $basic;
                }
            }
        }
        
    }
}


.navbar-custom {
    padding: 10px 0px;
    width: 100%;
    border-radius: 0px;
    z-index: 999;
    margin-bottom: 0px;
    transition: all 0.5s ease-in-out;
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);
    .container {
        padding-inline: 0;
    }
    h1{
        font-size: 28px;
        color: $black;
    }
    .logo {
        .logo-dark {
            display: none;
        }
        .ligo-light {
            display: inline-block;
        }
    }
    .link-login {
        display: none;
    }
    .button-login {
       
        font-size: 16px;
        background-color: $basic;
        border: $basic;
        color: #fff;
        border-radius: 15px;
        padding: 7px 22px;
        box-shadow: 0px 0px 12px rgba(241, 88, 46, 0.5);
    }
    
    
    .navbar-nav {
        .dropdown-toggle.nav-link {
            margin: 0 15px;
            cursor: pointer;
        }
       
        li {
            a {
                line-height: 26px;
                // color: $black;
                // font-size: 16px;
                font-size: 16px;
                // font-weight: 600;
                transition: all 0.3s;
                background-color: transparent !important;
                // padding: 6px 0;
                // margin: 0 18px;
                margin: 0 15px;
            }
            .nav-link {
                padding-right: 0px;
                padding-left: 0px;
                // color: $black
            }  
            
            .nav-link:hover {
                color: $basic;
            }
        }
        li.active {
            .nav-link {
                color: $basic;
            }
           
        }

        li.active a::after, li a:hover::after, li a:active::after, .dropdown-item.active::after{
            content: '';
            display: block;
            border:  1px solid $basic;
            color: $basic;
            // width: 31px;
            margin: auto;
            // margin-top: 3px;
            border-radius: 10px;  
        }
        .dropdown-item:hover, .dropdown-item.active  {
            color: $basic!important;
        }

    
        
        &.menu-social-icon {
            .nav-item {
                .nav-link {
                    margin: 0 10px !important;
                }
            }
        }
    }
    .navbar-toggler {
        padding: .4rem .75rem;
        font-size: 22px;
        // background: red;
        border: 1px solid $basic;
        color: $white;
        outline: 3;

        .svg-inline--fa{
            // display: block;
            color: $basic;
        }
    }

    .navbar-toggler:active   {
        border: 2px solid $basic;
    }


}

.navbar-toggler {
    font-size: 24px;
    margin-top: 5px;
    margin-bottom: 0px;
    color: $white;

  
}




.nav .open>a,
.nav .open>a:focus,
.nav .open>a:hover {
    background-color: transparent;
    border-color: $basic;
}

.menu-toggle {
    padding: 4.5px 10px !important;
    span {
        line-height: 27px;
    }
}


// NAVBAR LIGHT

.navbar-light {
    .navbar-nav {
        li {
            a {
                color: rgba($white, 0.6) !important;
            }
        }
        li.active a, li a:hover, li a:active {
            color: $white !important;
        }
    }
    .menu-social-icon {
        li {
            margin: 0 8px;
            .menu-social-link {
                color: rgba($white, 0.6) !important;
                transition: all 0.5s;
                &:hover {
                    color: $white !important;
                }
            }
        }
        
    }
    .logo {
        .logo-dark {
            display: none !important;
        }
        .logo-light {
            display: inline-block !important;
        }
    }
}


/*---NAVBAR STICKY--*/

.nav-sticky {
    &.navbar-custom {
        margin-top: 0px;
        background-color: $white;
        
        color: $black !important;
        .navbar-toggles {
            padding: .25rem .75rem;
            border: 1px solid transparent;
            outline: 0;
        }
        .navbar-nav {
           
            margin-top: 0px;
            li {
                a {
                    color: darken($muted, 6%) !important;
                }
            }
            li.active a, li a:hover, li a:active {
                color: $basic !important;
            }
        }
    }
    
    .logo {
        .logo-dark {
            display: inline-block !important;
        }
        .logo-light {
            display: none !important;
        }
    }
}

.navbar-toggler {
    &:focus {
        outline: none;
    }
}




@media  (max-width: 992px) {
    .navbar-custom {
    .navbar-nav {
        margin-inline: 12px;
        // display: flex!important;
        height: 15vh;
        // text-align: center;
        // justify-content: space-around;
        // overflow-y: scroll;
    }
}
.link-login-collapse {
    display: none;
}

.link-login {
    display: inline!important;
}
}


@media  (max-width: 768px) {
    .navbar-custom {
        margin-top: 0px;
        background-color: #fff !important;
        color: $white !important;
        .navbar-nav {
            height: 30vh!important;
          
            margin-top: 10px;
            li {
                a {
                    transition: all 0.4s;
                    // color: $white !important;
                    margin: 0px;
                }
                &.active {
                    a {
                        border-color: transparent;
                    }
                }
            }
            li.active a, li a:hover, li a:active {
                color: $basic !important;
            }
        }
        &>.container {
            width: 90%;
        }
        .logo {
            .logo-dark {
                display: inline-block !important;
            }
            .logo-light {
                display: none !important;
            }
        }
        &.navbar-light {
            
            .navbar-nav {
               
                li.active a, li a:hover, li a:active {
                    color: $dark !important;
                }
            }
        }

        .dropdown-toggle.nav-link {
            margin-left: 0!important;
        }
        
    }
    .navbar {
        .menu-social-icon {
            li {
                margin: 0 8px;
                .menu-social-link {
                    color: $muted !important;
                    transition: all 0.5s;
                    &:hover {
                        color: $basic !important;
                    }
                }
            }
        }
    }
    .nav-sticky {
        &.navbar-custom {
            .navbar-nav{
                margin-top: 10px;
            }   
        }
    }
    .navbar-toggler {
        font-size: 24px;
        margin-top: 0px;
        margin-bottom: 0px;
        color: $dark;
    }
    .navbar-collapse {
        padding: 0 20px;
    }
    .navbar-light {
        .nav-item {
            .nav-link:after {
                background: $dark;
            }
        }
    }
}

@media  (max-width: 500px) {
    .navbar-custom {
   
        .navbar-nav {
            width: 100%;
            height: 40vh!important;
            // flex-direction: column;
            display: block!important;
            overflow-y: scroll;
        }
        
        
    }
   
}


