#layanan{
    padding-top: 74.2px;
    position: relative;
    width: 100%;
    overflow: hidden;
    .top-dekor{
        position: absolute;
        width: 46px;
        height: 27px;
        top: 0;
        left: 48.4%;
        right: 48.4%;
    }
    h3{
        margin-bottom: 0;
        color: $black;
        font-weight: 600;
        font-size: 33.6px;
        line-height: 50.4px;
        text-align: center !important;
    }
    h4{
        font-weight: 500;
        font-size: 22.4px;
        line-height: 33.6px;
        color: $black;
        text-align: center;
        margin: 0;
        margin-top: 14.7px;
    }
    .split{
        margin: 79px 0 162px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: start;
        row-gap: 20px;
        ul{
            display: grid;
            max-width: 329px;
            font-weight: 500;
            font-size: 16.8px;
            line-height: 25.2px;
        }
        img{
            width: 413px;
            height: 366px;
        }
    }
}
.ck{
    position: relative;
    left: 30px;
}