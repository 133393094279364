#clients{
    background-color: #FFF1F1;
    color: $black !important;
    .card-color {
        width: 340px;
        border-radius: 28px !important;
        background-image: linear-gradient(to right bottom, #FF5C30, #B0B0B0);
        padding: 5px;
        .card-white{
            padding: 52px 0;
            border: none;
            border-radius: 28px !important;
            background-color: $white !important;
            .quotes-cover{
                display: flex;
                justify-content: center;
                align-items: start;
                span{
                    font-weight: 500 !important;
                    font-size: 49px !important;
                    line-height: 67px !important;
                }
                .quotes{
                    margin-top: 20px;
                    width: 197px;
                    font-weight: 500;
                    font-size: 14px !important;
                    line-height: 21px !important;
                }
            }
            .user{
                font-weight: 600 !important;
                font-size: 17px !important ;
                line-height: 25px !important;
            }
            .info{
                font-weight: 500 !important;
                font-size: 17px !important ;
                line-height: 25px !important;
            }
            img{
                width: 174px !important;
                height: 174px !important;
            }
        }
    }
    #testi-clients{
        position: relative;
        button{
            position: absolute;
            z-index: 3;
            margin: 0;
            border-radius: 100%;
            overflow: hidden;
            border: none;
            .btn-white{
                box-shadow:4px 4px 20px rgba(255, 95, 51, 0.35) !important;
                border: none;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;
                border-radius: 100%;
                background-color: $white !important;
                width: 77px;
                height: 77px;
                img{
                    height: 22px;
                    width: 13px;
                }
            }
        }
    }
    .prev{
        background-image: linear-gradient(to right, #FF5C30, #ffffff);
        padding: 1px 2px 1px 5px;
        top: 210px;
        left: -30px;
    }
    .next{
        background-image: linear-gradient(to left, #FF5C30, #ffffff);
        padding: 1px 5px 1px 2px;
        right: -30px;
        top: 210px;
    }

}

@media (min-width:700px) and (max-width: 900px) {
    #clients{
        padding: 50px 0;
        .btn-white{
            width: 55px !important;
            height: 55px !important;
            img{
                height: 15px !important;
                width: 10px !important;
            }
        }
        .next{
            right: -40px;
        }
        .prev{
            left: -20px;
        }
    }
}

@media only screen and (max-width: 480px) {
    #clients{
        padding: 50px 0;
        .btn-white{
            width: 55px !important;
            height: 55px !important;
            img{
                height: 15px !important;
                width: 10px !important;
            }
        }
        .next{
            right: -10px;
        }
        .prev{
            left: -10px;
        }
    }
}