.info-jumlah {
    display: flex;
    flex-direction: row;
    justify-content: center;
    .info-left {
        display: flex;
        gap: 0;
        margin-top: 40px;
        .extras {
            .rectangle1 {
                // position: absolute;
                margin-top: 20px;
                width: 80px;
                height: 80px;
                background: #EBEBEB;
                border-radius: 137.5px 0px 0px 0px;
            }
            .rectangle2 {
            
                width: 80px;
                height: 80px;
                background: #C8E6C9;
                border-radius: 0px 137.5px 0px 0px;
                transform: rotate(-180deg);
            }
        }
        
        .main-info {
            right: 40px;
            position: relative;
            .top-left {
                max-width: 130px;
                text-align: center;
                background-color: #D24018;
                color: $white;
                padding: 40px 30px;
                border-radius: 0px 70px 0px 0px;
            }
            .bottom-left {
                max-width: 130px;
                text-align: center;
                background-color: #F1582E;
                color: $white;
                padding: 40px 30px;
                border-radius:  0px  0px  0px 70px;
            }
        }
    }

    .info-right {  
        display: flex;
        gap: 0;
            .main-info {
            z-index: 1;
                .top-right {
                    max-width: 130px;
                    text-align: center;
                    background-color: #F1582E;
                    color: $white;
                    padding: 40px 30px;
                    border-radius: 0px 70px 0px 0px;
                }
                .bottom-right {   
                    max-width: 130px;  
                    text-align: center;    
                    background-color: #D24018;
                    color: $white;
                    padding: 40px 30px;
                    border-radius:  0px  0px  0px 70px;
                    
                }

            }
            
            .extras {
                bottom: 21px;
                right: 20px;
                position: relative;
                .rectangle1 {
                    width: 80px;
                    height: 80px;
                    background: #EBEBEB;
                    border-radius: 137.5px 0px 0px 0px;
                }
                .rectangle2 {
                    width: 80px;
                    height: 80px;
                    background: #C8E6C9;
                    border-radius: 93px 0px 0px 0px;
                    transform: rotate(-180deg);
                }

                .rectangle3 {        
                    width: 80px;
                    height: 80px;
                    background: #C8E6C9;
                    border-radius: 0px 137.5px 0px 0px;
                }
                .rectangle4 {
                    width: 80px;
                    height: 80px;
                    background: #EBEBEB;
                    border-radius: 0px 137px 0px 0px;
                    transform: rotate(-180deg)
                }
            }  
    }
}