.container-slider {
    padding-top: 74.2px;
    position: relative;
    width: 100%;
    overflow: hidden;
  
    .top-dekor {
      position: absolute;
      width: 46px;
      height: 27px;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  
    h3 {
      margin-bottom: 0;
      color: $black;
      font-weight: 600;
      font-size: 33.6px;
      line-height: 50.4px;
      text-align: center;
    }
  
    h4 {
      font-weight: 500;
      font-size: 22.4px;
      line-height: 33.6px;
      color: $black;
      text-align: center;
      margin: 0;
      margin-top: 14.7px;
    }
  
    .slick-list {
      margin: 112.7px 0 178.5px 0;
      min-width: 100%; // Adjust for responsiveness
  
      .image-slider {
        border: 10px solid #f1582e; // Reduced border size for balance
        overflow: hidden;
        border-radius: 60px 0 90px 0; // Adjusted for smoother edges
        position: relative;
        width: 100%;
        max-width: 400px;
        aspect-ratio: 16 / 9; // Maintain aspect ratio
        margin: 0 auto; // Center-align images in the container
  
        img {
          background-color: white;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
  
        .gradien-text {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-image: linear-gradient(to bottom, transparent, #f1582e);
          color: white;
          display: flex;
          justify-content: start;
          align-items: end;
  
          p {
            font-weight: 700;
            font-size: 16px;
            line-height: 25px;
            color: white;
            margin: 0 0 17.5px 12.6px;
          }
        }
      }
    }
  
    .slick-dots {
      position: absolute;
      bottom: -45px !important;
    }
  
    .slick-arrow {
      display: none !important;
    }
  
    // Media Queries for Responsive Border and Layout
    @media (max-width: 1024px) {
      h3 {
        font-size: 28px;
        line-height: 42px;
      }
  
      h4 {
        font-size: 18px;
        line-height: 28px;
        margin-top: 10px;
      }
  
      .slick-list {
        margin: 80px 0 150px 0;
  
        .image-slider {
          border: 8px solid #f1582e; // Proportional border for smaller screens
          border-radius: 50px 0 70px 0;
          max-width: 350px;
        }
      }
    }
  
    @media (max-width: 768px) {
      h3 {
        font-size: 24px;
        line-height: 36px;
      }
  
      h4 {
        font-size: 16px;
        line-height: 24px;
        margin-top: 8px;
      }
  
      .slick-list {
        margin: 60px 0 120px 0;
  
        .image-slider {
          border: 6px solid #f1582e;
          border-radius: 40px 0 60px 0;
          max-width: 100%;
        }
      }
    }
  
    @media (max-width: 480px) {
      h3 {
        font-size: 20px;
        line-height: 30px;
      }
  
      h4 {
        font-size: 14px;
        line-height: 21px;
      }
  
      .slick-list {
        margin: 40px 0 100px 0;
  
        .image-slider {
          border: 4px solid #f1582e; // Smaller border for compact screens
          border-radius: 30px 0 50px 0;
        }
      }
    }
  }
  