#dusun {
    position: relative;
    overflow: hidden;
    padding: 74.2px 72.8px;
    .container {
        .top-dekor{
            position: absolute;
            width: 46px;
            height: 27px;
            top: 0;
            left: 48.4%;
            right: 48.4%;
        }
        .content-wrapper {   
            .left-side {
                h3 {
                    font-weight: 600;
                    font-size: 33.6px;
                    
                }
                h4 {
                    font-weight: 500;
                    font-size: 20px;
                }
                    
                img {
                    width: 80%;
                }
            }
        }
       
    }   
}

@media (max-width: 992px )  {
    #dusun {
        .container {
        
            .content-wrapper {
                .left-side {
                    margin-bottom: 50px;
                    text-align: center;
                        h3 {
                            
                            font-size: 28.6px;
                        }
                        h4 {
                       
                            font-size: 18px;
                            br {
                                display: none;
                            }
                        }
                        img {
                            width: 100%;
                            margin-top: 20px;
                        }
                    
                }
            }
            
        }   
    }

}

@media (max-width: 700px )  {
    #dusun {
         padding-inline: 30px;
        .container {
           
            .content-wrapper {
               
                .left-side {
                    padding-inline: 0;
                    margin-inline: 0;
                    h3 {
                        
                        font-size: 24px;
                    }
                    h4 {
                   
                        font-size: 16px;
                    }
                    img {
                        margin-inline: 0;
                        width: 100%!important;
                    }
                }
              
            }
            
        }   
    }

}

    