.carousel.slide {
    .carousel-control-prev-icon,
    .carousel-control-next-icon {
        display: none;
    }

    /* Indicators Style */
    .carousel-indicators {
        margin-bottom: 10vh;

        li {
            background-color: transparent;
            margin: 3.5px;
            border: 2.1px solid #F1582E;
            opacity: 1;
            border-radius: 100%;
            width: 10.5px !important;
            height: 10.5px !important;
        }

        li.active {
            width: 35px !important;
            background-color: #F1582E;
            border-radius: 50px;
        }
    }

    /* Carousel Content Styling */
    .carousel-inner {
        z-index: inherit;

        .carousel-item:first-child {
            .image-slider {
                width: 100%;
                height: 100vh;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: background-position 0.5s ease, background-size 0.5s ease;
            }
        }
    }

    /* Each Slide Styling */
    .carousel-item {
        width: 100%;

        .image-slider {
            height: 100vh;
            width: 100vw;
            background-size: cover;
            background-repeat: no-repeat;

            /* Caption Styling */
            .carousel-caption {
                position: absolute;
                left: 100px;
                bottom: 25%;
                text-align: left;
                color: white;
                max-width: 40%;
                padding: 10px;
                font-size: clamp(14px, 2vw, 18px);
            }

            h3 {
                font-size: 2vw;
            }

            p {
                font-size: 2vw;
                margin-top: 20px;
            }

            /* Hero Image Styling */
            .hero-image {
                position: absolute;
                bottom: -115px;
                left: 65%;
                transform: translateX(-50%);
                max-width: 100%;
                height: auto;
                width: 150vw !important;
                filter: brightness(70%);
            }

            /* Specific Hero Image Styles */
            .hero-image.slide-1 {
                bottom: -120px;
                left: 50%;
                width: 100% !important;
                filter: brightness(70%);
            }

            .hero-image.slide-2 {
                bottom: -70px;
                right: 0px;
                width: 70vmax !important;
                filter: brightness(70%);
            }

            .hero-image.slide-3 {
                bottom: -70px;
                right: 0px;
                width: 70% !important;
                filter: brightness(90%);
            }
        }
    }
}

/* Responsive Styles */

/* Large screens (max-width: 1500px) */
@media (max-width: 1500px) {
    .carousel-caption h3 {
        font-size: 1.5rem;
        text-align: center !important;
    }

    .carousel-caption p {
        font-size: 1.4rem;
    }
}

/* Styling tambahan untuk ukuran layar yang lebih kecil */
@media (max-width: 992px) {
    .carousel-caption {
        width: 90%; /* Kurangi lebar untuk layar lebih kecil */
    }

    .carousel-caption h3 {
        font-size: 1.8rem; /* Kurangi ukuran font */
    }

    .carousel-caption p {
        font-size: 1.4rem;
    }
}


/* Small screens (max-width: 767px) */
@media (max-width: 767px) {
    .carousel-inner .carousel-item:first-child .image-slider {
        background-position: center;
        height: 60vh;
    }

    .carousel-caption {
        display: block !important;
        width: 100%;
        transform: translateX(50%);
        top: 5% !important;
        left: 10% !important;
        text-align: center !important;

        h3 {
            margin-top: 10vh;
            font-size: 1.5rem !important;
        }

        p {
            font-size: 1.4rem !important;
        }
    }

    .hero-image {
        width: 100vw !important;
    }
}

/* Extra Small screens (max-width: 576px) */
@media (max-width: 576px) {
    .carousel-caption {
        top: 10% !important;
        left: 10% !important;
        transform: translateX(50%);
        text-align: center !important;

        h3 {
            margin-top: 10vh;
            font-size: 1.2rem !important;
        }

        p {
            font-size: 1.1rem !important;
        }
    }

    .image-slider .hero-image {
        width: 100vw !important;
        height: auto;
        max-width: 100%;
        margin-bottom: 70px;
    }
}
