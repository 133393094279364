#PetaDesa {
    width: 100%;
    height: 800px;
}

.PetaDesa-container {
    width: 100%;
    height: 500px;
    display: flex;
    flex-flow: row wrap;
}

.headtext {
    font-size: 25px;
    font-weight: 700;
}

// .petadesaimg {
//     width: 700px;
// }

.marker-desakami {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 15px;
}

.content-PetaDesa {
    display: flex;
    justify-content: center;
    width: 100%;
}

.computer-wrapper {
    position: relative;
    // transform: translate(50%, -50%);
  
    iframe {
        margin-top: 23px;
        margin-left: 21px;
        position: absolute;
        right: 24px;
        top: 2px;
    }
    .petadesaimg {
        // z-index: 999;
        width: 700px;;
    }
}



@media (max-width: 768px) {
    .petadesaimg {
            width: 400px !important;
        }
    #PetaDesa {
        height: 500px;
        .PetaDesa-container {
            height: 300px;
        }
    }

    .computer-wrapper {
        iframe {
            width: 370px;
            height: 208px;
            left: -5.5px;
            top: -7px;
        }
    }
}