#article {
    width: 100%;
    height: auto; /* Adjusts to content height */
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
}

.article-container {
    max-width: 100%;
}

.first-line, .second-line {
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.left, .right {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    box-sizing: border-box;
}

.mark {
    background-color: #ffffff;
}

.marker {
    width: 30px;
    padding-bottom: 25px;
}

.ular {
    width: 100%;
    max-width: 600px; /* Ensures the image doesn't stretch too far */
}

.articleborder {
    padding: 10px;
    width: 100%; /* Full width within its container */
    max-width: 500px; /* Prevents it from getting too wide */
    height: 200px;
}

.articlehead {
    font-weight: 600;
    font-size: 20px;
    padding-bottom: 5px;
}

.articlehead p {
    color: #000000;
}

.articletxt {
    font-size: 15px;
    text-align: justify;
}

.date {
    font-size: 12px;
    color: grey;
}

.next-article {
    width: 90%;
    height: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
}

.next-article p {
    color: #f85c2c;
    font-weight: 400;
}

.next-article img {
    padding-left: 4px;
    width: 10px;
    height: 13px;
}

@media (max-width: 768px) {
    #article {
        height: auto; /* Adjust height based on content */
        flex-direction: column; /* Stack the content vertically */
    }

    .first-line, .second-line {
        height: auto;
        flex-direction: column;
        display: flex;
    }

    .left, .right {
        width: 100%;
        height: auto; /* Adjust to content */
        img {
            max-width: 100%; /* Ensures images fit within their container */
            height: auto;
        }
    }

    .left {
        order: -1; /* Moves the left block above the right block */
    }

    .articleborder {
        width: 100%;
        max-width: none; /* Removes max-width limitation */
        height: auto;
    }

    .articlehead {
        font-size: 18px; /* Adjust font size for smaller screens */
    }

    .articletxt {
        font-size: 14px; /* Adjust font size for smaller screens */
    }

    .date {
        font-size: 8px; /* Adjust font size for smaller screens */
    }
}
