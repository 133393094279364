//
// general.scss
//
 html , body{
    overflow-x: hidden!important;
    box-sizing: border-box;
}

a {
    text-decoration: none !important;
    outline: none;
    color: $white;
}
body {
    font-family: 'Poppins', sans-serif;
    color: $dark;
    background: $white;
}
p {
    line-height: 1.6;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Poppins', sans-serif;
}

#article {
   overflow-x: hidden;
}



