#fasilitas{
    position: relative;
    overflow: hidden;
    padding: 74.2px 72.8px;
    .top-dekor{
        position: absolute;
        width: 46px;
        height: 27px;
        top: 0;
        left: 48.4%;
        right: 48.4%;
    }
    h3{
        margin-bottom: 0;
        color: $black;
        font-weight: 600;
        font-size: 33.6px;
        line-height: 50.4px;
        text-align: center;
    }
}