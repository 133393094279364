#komoditas{
    padding: 74.2px 0 271px 0;
    position: relative;
    .top-dekor{
        position: absolute;
        width: 46px;
        height: 27px;
        top: 0;
        left: 48.4%;
        right: 48.4%;
    }
    h3{
        margin-bottom: 0;
        color: $black;
        font-weight: 600;
        font-size: 33.6px;
        line-height: 50.4px;
        text-align: center;
        margin-bottom: 14.7px;
    }
    h4{
        font-weight: 500;
        font-size: 22.4px;
        line-height: 33.6px;
        color: $black;
        text-align: center;
        margin: 0;
        width: 341px;
        margin: auto;
    }
    .komoditas-container{
        margin-top: 131px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        column-gap: 85px;
        row-gap: 70px;
        .komoditas{
            position: relative;
            border-radius: 100%;
            width: 154px;
            height: 154px;
            background-size: cover;
            display: flex;
            justify-content: center;
            align-items: center;
            h4{
                max-width: 110px;
                z-index: 3;
                color: white !important;
                width: auto;
                font-weight: 600;
            }
        }
        .komoditas::before{
            z-index: 2;
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            background: rgba(0, 0, 0, 0.2);
            border-radius: 100%;
        }
    }
}