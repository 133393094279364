#visi-misi {
    margin-block: 100px;
    .container {
        // max-height: fit-content;
        .top-dekor{
            margin-bottom: 40px;
            width: 46px;
            height: 27px;
        }
        .left-side {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            h3{
                margin-bottom: 20px;
                color: $black;
                font-weight: 600;
                font-size: 33.6px;
                line-height: 50.4px;
            }
            p {
                font-weight: 500;
                font-size: 20px;
            }
        }
         
        .righ-side {
            img {
                margin-top: 30px;
                width: 100%;
            }
        }
    }   
   
}
    