#DesaKami {
    width: 100%;
    height: 650px;
}

.DesaKami-container {
    width: 100%;
    height: 500px;
    display: flex;
    flex-flow: row wrap;
}

.vidyt {
    /* Uncomment if needed */
    /* border-radius: 20px; */
}

.headtext {
    font-size: 25px;
    font-weight: 700;
}

.content-text, .content {
    display: flex;
    justify-content: center;
    width: 100%;
}

.content-text {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
}

.headline-content, .text-content {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
}

.marker-desakami {
    width: 50%;
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
    margin-top: auto;
}

.content {
    position: relative;
}

.play-button {
    width: 250px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    cursor: pointer;
}

@media (max-width: 768px) {
    /* Optionally adjust height on smaller screens */
    #DesaKami {
        /* height: 700px; */ 
    }

    .video-player {
        width: 90%; /* Adjust width for smaller screens */
        height: auto;
        border-radius: 0;
    }
   
    .play-button {
        width: 160px; /* Adjust size of play button */
    }
}

@media (max-width: 576px) {
    /* Adjust video player size on very small screens */
    .video-player {
        height: 250px; /* Make the video player smaller */
    }

    .play-button {
        top: 45%;
        width: 140px; /* Smaller play button for smaller screens */
    }
}
