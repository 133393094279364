#struktur{
    padding: 74.2px 0 102px 0 ;
    position: relative;
    width: 100%;

    .struktur-desa-img {
        width: 80vw;
        margin: 0 auto;
    }
    .top-dekor{
        position: absolute;
        width: 46px;
        height: 27px;
        top: 0;
        left: 48.4%;
        right: 48.4%;
    }
    h3{
        margin-bottom: 20px;
        color: $black;
        font-weight: 600;
        font-size: 33.6px;
        line-height: 50.4px;
        text-align: center;
    }
    h4{
        font-weight: 500;
        font-size: 22.4px;
        line-height: 33.6px;
        color: $black;
        text-align: center;
        margin-bottom: 67px;
    }

    .struktur-wrapper {
        display: flex;
        justify-content: center;

    
        img {
            margin: auto;
        }

      
    }
   

    .initechNode {
        border: solid 3px $basic;
        font-size: 0.9rem;
        color: $white;
        background-color: $basic;
        border-radius: 3px;
        padding: 5px;
        width: 200px;
        display: inline-block;

        // .struktur-wrapper {
        //     display: grid;
        //     grid-template-columns: 1fr 4fr;
        
        //     img {
        //         bottom: 0;
        //     }

        //     .description {
        //         margin: auto;
        //         font-size: 8px;
        //     }
        // }
        
    }


    .reactOrgChart .orgNodeChildGroup .nodeGroupLineVerticalMiddle {
        border-right: solid 2px $basic; 
    }

    .reactOrgChart .nodeLineBorderTop {
        border-top: solid 2px $basic;
    }

    
}

@media (max-width: 992px) {
    #struktur {
        .initechNode {
            font-size: 0.8rem;
            width: 100px;
        }
    }
 
}

@media (max-width: 767px) {
    #struktur {
        .initechNode {
            font-size: 0.7rem;
            width: 80px;
        }
    }
 
}

@media (max-width: 560px) {
    #struktur {
        .initechNode {
            font-size: 0.6rem;
            width: 50px;
        }
    }
 
}

@media (max-width: 360px) {
    #struktur {
        .initechNode {
            font-size: 0.5rem;
            width: 40px;
        }
    }
 
}